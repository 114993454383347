import Swiper, { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function initSwipers() {
  let homebuildingSwiper;
  let testimonialsSwiper;
  let residentialSwiper;
  let commercialGallery;
  let agricultureGallery;

  homebuildingSwiper = new Swiper(`.homebuilding__swiper`, {
    slidesPerView: 'auto',
    spaceBetween: 25,
    loop: true,
    pagination: {
      el: '.homebuilding__swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.homebuilding__swiper-next',
      prevEl: '.homebuilding__swiper-prev',
    },
    modules: [Navigation, Pagination],
    centeredSlides: true,
    centerMode: true,
    enabled: true,
    breakpoints: {
      1480: {
        enabled: false,
        loop: false,
        centerMode: false,
        centeredSlides: false
      }
    }
  });
  
  testimonialsSwiper = new Swiper(`.testimonials__swiper`, {
      slidesPerView: 'auto',
      spaceBetween: 25,
      loop: true,
      autoWidth: true,
      pagination: {
        el: '.testimonials__swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.testimonials__swiper-next',
        prevEl: '.testimonials__swiper-prev',
      },
      modules: [Navigation, Pagination],
      centeredSlides: true,
  });

  residentialSwiper = new Swiper('.residential__swiper', {
    slidesPerView: 'auto',
    spaceBetween: 25,
    loop: true,
    autoWidth: true,
    effect: 'fade',
    pagination: {
        el: '.gallery__swiper-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.swiper-gallery-next',
        prevEl: '.swiper-gallery-prev',
    },
    centeredSlides: true,
    centerMode: true,
    modules: [Navigation, Pagination],
});

commercialGallery = new Swiper('.commercial__swiper', {
    slidesPerView: 'auto',
    spaceBetween: 25,
    autoWidth: true,
    effect: 'fade',
    loop: true,
    pagination: {
        el: '.gallery__swiper-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.swiper-gallery-next',
        prevEl: '.swiper-gallery-prev',
    },
    modules: [Navigation, Pagination],
    centeredSlides: true,
    centerMode: true
});

agricultureGallery = new Swiper('.agriculture__swiper', {
    slidesPerView: 'auto',
    spaceBetween: 25,
    loop: true,
    autoWidth: true,
    pagination: {
        el: '.gallery__swiper-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.swiper-gallery-next',
        prevEl: '.swiper-gallery-prev',
    },
    modules: [Navigation, Pagination],
    centeredSlides: true,
    centerMode: true,
});

const swipers = [
  homebuildingSwiper,
  testimonialsSwiper,
  residentialSwiper,
  commercialGallery,
  agricultureGallery,
]

// window.addEventListener('load', () => {
//   swipers.forEach(swiper => {
//     if (window.innerWidth <= 768) {
//       swiper.cssMode = true
//     } else {
//       swiper.cssMode = false    
//     }
//       swiper.init();
//     });
// });

// window.addEventListener('resize', () => {
//   swipers.forEach(swiper => {
//     if (window.innerWidth <= 768) {
//       swiper.cssMode = true
//     } else {
//       swiper.cssMode = false    
//     }
//       swiper.init();
//     });
//   });
}


export default initSwipers;