import './styles/main.scss';
import 'fslightbox';

import initNav from './js/initNav';
import initSwipers from './js/initSwipers';
import initContactForm from './js/initContactForm';
import initGalleryMenu from './js/initGalleryMenu';
import onePageNav from './js/onePageNav';

initGalleryMenu();
initSwipers();
initNav();
initContactForm();

$('#desktopLinks').onePageNav({
	currentClass: 'active',
	changeHash: true,
	scrollSpeed: 250,
	scrollThreshold: 0.25,
	filter: '',
	easing: 'swing',
	begin: function() {
		//I get fired when the animation is starting
	},
	end: function() {
		//I get fired when the animation is ending
	},
	scrollChange: function($currentListItem) {

	}
});

$('#mobileLinks').onePageNav({
	currentClass: 'active',
	changeHash: true,
	scrollSpeed: 250,
	scrollThreshold: 0.25,
	filter: '',
	easing: 'swing',
	begin: function() {
		//I get fired when the animation is starting
	},
	end: function() {
		//I get fired when the animation is ending
	},
	scrollChange: function($currentListItem) {

	}
});