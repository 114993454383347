const url = 'https://zanuttaconstruction.com/php/contact_me.php';

const submitBtn = document.querySelector('#submitBtn');
const contactResponse = document.querySelector('#contactResponse');
const formInputs = document.querySelectorAll('.contactPopup__input');
const popupClose = document.querySelector('.contactPopup__close');

const userFirstName = document.querySelector(".contactPopup__input[name='firstName']");
const userLastName = document.querySelector(".contactPopup__input[name='lastName']");
const userEmail = document.querySelector(".contactPopup__input[name='email']");
const userPhone = document.querySelector(".contactPopup__input[name='phone']");
const userPostalCode = document.querySelector(".contactPopup__input[name='postalCode']");
const userTypeOfProject = document.querySelector(".contactPopup__input[name='typeOfProject']");
const userHasDesignLayouts = document.querySelector("#designLayouts");
const userFileContainer = document.querySelector(".contactPopup__input--file");
const userFile = document.querySelector("#file");
const userMessage = document.querySelector(".contactPopup__input[name='message']");

function initContactForm() {
    submitBtn.addEventListener('click', () => submitContactForm());
    formInputs.forEach(input => input.addEventListener('input', () => {
        input.classList.remove('input-error');
        contactResponse.textContent = "";
        contactResponse.classList.remove('active');
    }));
}


function postForm(data) {        
    $.ajax({
        url : url,
        type: 'POST',
        data : data,
        contentType: false,                  
        processData: false,  
    })
    .done((res) => {
        console.log(res)
        contactResponse.textContent = 'Thank you! We will be in touch shortly.'
        contactResponse.classList.add('active');

        setTimeout(() => {
            contactResponse.classList.remove('active');
            contactResponse.textContent = "";

            formInputs.forEach(input => input.value = '');
        }, 3000);
    }).fail(err => {
        contactResponse.textContent = 'Something went wrong...'
        contactResponse.classList.add('active');
        console.log(err);
    });
}


userHasDesignLayouts.addEventListener('input', () => {
    if (userHasDesignLayouts.value === 'Yes') {
        userFileContainer.classList.remove('hidden');
    } else {
        userFileContainer.classList.add('hidden');
    }
});

userFile.addEventListener('input', () => {
    const copy = document.querySelector('#filesCopy');
    userFile.files.length > 0 ? copy.textContent = '1 file attached' : 'Click here to upload your file'
})

function submitContactForm() {
    let formValid = true;

    const requiredFields = [
    userFirstName,
    userLastName,
    userEmail,
    userPhone,
    userPostalCode,
    userTypeOfProject,
    userHasDesignLayouts,
    userMessage
];

    requiredFields.forEach(input => {
        if (input.value === "") {
            formValid = false;
            input.classList.add('input-error');
            contactResponse.textContent = "Please fill out all required fields";
            contactResponse.classList.add('active');
        }
    });

    if (formValid) {
        const formData = new FormData();

        formData.append('userFirstName', userFirstName.value)
        formData.append('userLastName', userLastName.value)
        formData.append('userEmail', userEmail.value)
        formData.append('userPhone', userPhone.value === "" ? "No phone number provided" : userPhone.value)
        formData.append('userPostalCode', userPostalCode.value === "" ? "No postal code provided" : userPostalCode.value)
        formData.append('userTypeOfProject', userTypeOfProject.value === "" ? "No project type given" : userTypeOfProject.value)
        formData.append('userHasDesignLayouts', userHasDesignLayouts.value === "" ? "No project files" : userHasDesignLayouts.value)
        formData.append('userMessage', userMessage.value === "" ? "No message provided" : userMessage.value)
        formData.append('userFile', userFile.files[0]);
        
        postForm(formData);

        setTimeout(() => {
            popupClose.click();
        }, 2500);
    }
}

export default initContactForm;